import React, { useState } from "react";
import { Typography, Modal, Button } from 'antd';
import { Link } from 'react-router-dom';
import Heading from "../../common/Heading";
import "./recent.css";
import RecentCard from "./RecentCard";

const { Paragraph } = Typography;

const Recent = () => {
  const [modalVisible, setModalVisible] = useState(false);

  // Function to handle opening and closing the modal
  const handleModal = () => {
    setModalVisible(!modalVisible);
  }

  return (
    <>
      <section className='recent padding'>
        <div className='container'>
          <Heading title='Properties For Sale' subtitle='.' />
          <Paragraph>
            <strong>§:</strong> Former Socius Nursery and Primary School is located in Kangundo District/ Machakos County. About 1.5 km from Kangundo township and 300 m from Kangundo Machakos main road. The School consists of two (2) properties A & B beside each other.
          </Paragraph>
          <Paragraph>
            <strong>§: Property A:</strong> Area 0.15 ha. Title deed. Three (3) buildings with a total of six (6) classrooms. Water well (deep) 42 ft / 1 water tank 10,000 Liters / 8 Latrines.
          </Paragraph>
          <Paragraph>
            <strong>§: Property B:</strong> Area 0.125 ha. Title deed. Two (2) buildings with a total of four (4) classrooms / four (4) office rooms. Kitchen with store / 1 big store outside (Ikumbi). Two (3) Latrines. Piped tap water / 1 water tank 10,000 / 1 water tank 5,000 liters. Power supply.
          </Paragraph>
          <Paragraph>
            <strong>§:</strong> Properties are located in residential and fenced around, a developed garden with a lot of trees and flowers. Good security and peaceful area, and no traffic disturbance.
          </Paragraph>
          <Paragraph>
            <strong>§: About us;</strong> We believe in making a difference in the local community.
          </Paragraph>
          
          <RecentCard />
          
          <div className='button-container'>
            <Link to="/blog" className="view-more-button">View More in Gallery</Link>
            <Button type="primary" style={{ marginLeft: '10px' }} onClick={handleModal}>View Title Deed</Button>
          </div>
          
          <div className='video-container'>
            <video 
              src="https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/document_6026348679797936784.mp4?alt=media&token=a7346fda-0fae-408e-8d61-d065026fa7a4" 
              controls 
              muted 
              autoPlay 
              loop 
              onClick={(e) => e.target.muted = !e.target.muted}
            />
          </div>
          <div className='video-container'>
            <video 
              src="https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Video%202024-07-04%20at%201.08.29%20PM.mp4?alt=media&token=1a3a3711-8260-4456-8bad-195c7b664746" 
              controls 
              muted 
              autoPlay 
              loop 
              onClick={(e) => e.target.muted = !e.target.muted}
            />
          </div>
        </div>

        {/* Modal for displaying title deed image */}
        <Modal
          title="Title Deed"
          visible={modalVisible}
          onCancel={handleModal}
          footer={[
            <Button key="back" onClick={handleModal}>
              Close
            </Button>,
          ]}
        >
          <img src="https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-07-06%20at%202.03.04%20PM.jpeg?alt=media&token=f56e02fe-e921-43a9-9c81-95cc3f57bfbc" alt="Title Deed" style={{ width: '100%' }} />
        </Modal>
        
      </section>
    </>
  );
};

export default Recent;
