import React from "react";
import Back from "../common/Back";
import RecentCard from "../home/recent/RecentCard";
import "../home/recent/recent.css";
import FeaturedCard from "../home/featured/FeaturedCard";

const Blog = () => {
  const imageUrl = 'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.23.58%20PM.jpeg?alt=media&token=a7b5c048-03b7-40da-84d4-dd8eb7f2a24e';

  return (
    <>
      <section className='blog-out mb'>
        <Back name='Blog' title='Blog Grid' cover={imageUrl} />
        <div className='container recent'>
          <FeaturedCard/>
        </div>
      </section>
    </>
  );
};

export default Blog;
