import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <section className='footerContact'>
        <div className='container'>
          <div className='send flex'>
            <div className='text'>
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className='container'>
          <div className='box'>
            <div className='logo'>
              <h2>Do You Have Any Questions About our Properties?</h2>
              <p>Contact Us Below:</p>
              <div className='contactInfo'>
                <p>1: +254712295739</p>
                <p>2: +46709653646</p>
                <p>3: +254720263095</p>
                <p>Email us:</p>
                <p>veronica.socius@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className='legal'>
        <span>© 2024 onSale.</span>
      </div>
    </>
  );
};

export default Footer;
