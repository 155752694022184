import React from "react";
import "./hero.css";

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <h1 className='title'>PROPERTY FOR SALE</h1>
          <h2 className='subtitle'>A and B</h2>
          <div className='content'>
            <div className='subtitle'>
              <span className='animating-subtitle'>Plot for Sale at Syanthi with Ready Title Deed</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
