export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
 
  {
    text: "blog",
    path: "/blog",
  },
 
  {
    text: "contact",
    path: "/contact",
  },
]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Family House",
    total: "122 Property",
  },
  {
    cover: "../images/hero/h2.png",
    name: "House & Villa",
    total: "155 Property",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Apartment",
    total: "300 Property",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Office & Studio",
    total: "80 Property",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Villa & Condo",
    total: "80 Property",
  },
]
export const list = [
  {
    id: 1,
    cover: "https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.15.41%20PM.jpeg?alt=media&token=f97246f7-8545-402c-ad0a-949f89b39a7d",
  
  },
  {
    id: 2,
    cover: "https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.15.16%20PM.jpeg?alt=media&token=7e210f70-ff28-4040-b276-6ce0e9ea1093",
   
  },
  {
    id: 3,
    cover: "https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.23.35%20PM.jpeg?alt=media&token=23795a43-696a-450e-9215-452bd3f8f325",
   
  },
  {
    id: 4,
    cover: "https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.23.58%20PM.jpeg?alt=media&token=a7b5c048-03b7-40da-84d4-dd8eb7f2a24e",
   
  },
  {
    id: 5,
    cover: "https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.24.20%20PM.jpeg?alt=media&token=62e20344-6623-4e55-b9c1-01d1fb5399c3",
 
  },
  {
    id: 6,
    cover: "https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.15.16%20PM.jpeg?alt=media&token=7e210f70-ff28-4040-b276-6ce0e9ea1093",
  
  },
  {
    id: 7,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.29%20PM%20(1).jpeg?alt=media&token=1dc8a022-873e-4cd2-a774-d56972633cfc',
  
  },
  {
    id: 8,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.29%20PM.jpeg?alt=media&token=2a6bcbfd-c7df-4b6f-8951-de3689b70940',
   
  },
  {
    id: 9,
   cover: 'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.28%20PM%20(1).jpeg?alt=media&token=7ba7e034-01ec-4413-bece-2cb9ae14f8ef',
  
  },
  {
    id: 10,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.28%20PM.jpeg?alt=media&token=9bce3f4b-c49b-4919-be04-0c3d1b5db7b9',
  },
  {
    id: 11,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.27%20PM%20(2).jpeg?alt=media&token=2a0a9990-8818-435b-ab89-68e8a699d1fe',
    
  
  },
  {
    id: 12,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.27%20PM%20(1).jpeg?alt=media&token=1be6185e-d822-45c9-bde7-d9f59e875ba4',
    
  
  },
 
  {
    id: 15,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2F7.jpg?alt=media&token=0cd43aa7-1865-4169-bcb2-6fc3175ac1a1',
    
  
  },
  {
    id: 16,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2F28.jpg?alt=media&token=5943cb81-2c64-47f1-a11e-04e381f63664',
    
  
  },
  {
    id: 17,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2F1%20(7).jpg?alt=media&token=9eab7c15-8b6b-4df2-a854-43f3a92a3df7',
    
  
  },
  {
    id: 18,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2F5.jpg?alt=media&token=a429861d-fda9-4673-a7af-05484015c763',
    
  
  },
  {
    id: 19,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2F1%20(8).jpg?alt=media&token=3cc205e2-eaad-49e8-8f93-fa90d03a1a46',
    
  
  },
  {
    id: 20,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2F3.jpg?alt=media&token=e89d2f03-fca3-4a53-b847-26b22e99f56f',
    
  
  },
  {
    id: 21,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2F17.jpg?alt=media&token=e8fc4e92-f33c-47a3-ae4b-d51c90b2bf4a',
    
  
  },
  {
    id: 22,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2F12.jpg?alt=media&token=5a4aad08-e275-4e27-be6b-3b1bc98a9d63',
    
  
  },
  {
    id: 23,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2F26.jpg?alt=media&token=fb6ce1e1-7339-4650-9e17-40bae17d80b4',
    
  
  },
  {
    id: 24,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2F4.jpg?alt=media&token=de5024ba-9dc7-4b7e-951e-e06f3e1725af',
    
  
  },
  {
    id: 25,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2F6.jpg?alt=media&token=08fb5548-ddbc-4bdb-bb54-82e0cbc69da3',
    
  
  },
  {
    id: 26,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2F9%20(1).jpg?alt=media&token=c99b2e14-fa70-45b0-9ba0-bbd5d1f79c04',
    
  
  },
  {
    id: 27,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2Fphoto_6026348680254179958_x.jpg?alt=media&token=65e34497-fea4-4430-b859-dbf9a34185c0',
    
  
  },
  {
    id: 28,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2Fphoto_6026348680254179959_x.jpg?alt=media&token=08bb51b5-d09f-4734-9601-425af675f364',
    
  
  },
  {
    id: 29,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2Fphoto_6026348680254179960_x.jpg?alt=media&token=4835a842-2eb9-42ed-9437-410cab68d926',
    
  
  },
  {
    id: 30,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2Fphoto_6026348680254179961_x.jpg?alt=media&token=25df2537-a11d-44d8-ac7c-1dc593b662d3',
    
  
  },
  {
    id: 31,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2Fphoto_6026348680254179962_x.jpg?alt=media&token=7b11df30-d350-429f-a971-75a2f56107b1',
    
  
  },
  {
    id: 32,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2Fphoto_6026348680254179963_x.jpg?alt=media&token=5062e406-a10e-48c7-aa87-bbe9f5330b45',
    
  
  },
  {
    id: 33,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/property%2Fphoto_6026348680254179964_x.jpg?alt=media&token=f430c752-951a-4c24-aec5-4559a946f22f',
    
  
  },
  {
    id: 13,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.27%20PM.jpeg?alt=media&token=97b28f49-e2b2-444b-9281-e86c038e8901',
    
  
  },
 
]

export const gallery = [
  {
    id: 1,
    cover: "https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.15.41%20PM.jpeg?alt=media&token=f97246f7-8545-402c-ad0a-949f89b39a7d",
  
  },
  {
    id: 2,
    cover: "https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.15.16%20PM.jpeg?alt=media&token=7e210f70-ff28-4040-b276-6ce0e9ea1093",
   
  },
  {
    id: 3,
    cover: "https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.23.35%20PM.jpeg?alt=media&token=23795a43-696a-450e-9215-452bd3f8f325",
   
  },
  {
    id: 4,
    cover: "https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.23.58%20PM.jpeg?alt=media&token=a7b5c048-03b7-40da-84d4-dd8eb7f2a24e",
   
  },
  {
    id: 5,
    cover: "https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.24.20%20PM.jpeg?alt=media&token=62e20344-6623-4e55-b9c1-01d1fb5399c3",
 
  },
  {
    id: 6,
    cover: "https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.15.16%20PM.jpeg?alt=media&token=7e210f70-ff28-4040-b276-6ce0e9ea1093",
  
  },
  {
    id: 7,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.29%20PM%20(1).jpeg?alt=media&token=1dc8a022-873e-4cd2-a774-d56972633cfc',
  
  },
  {
    id: 8,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.29%20PM.jpeg?alt=media&token=2a6bcbfd-c7df-4b6f-8951-de3689b70940',
   
  },
  {
    id: 9,
   cover: 'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.28%20PM%20(1).jpeg?alt=media&token=7ba7e034-01ec-4413-bece-2cb9ae14f8ef',
  
  },
  {
    id: 10,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.28%20PM.jpeg?alt=media&token=9bce3f4b-c49b-4919-be04-0c3d1b5db7b9',
  },
  {
    id: 11,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.27%20PM%20(2).jpeg?alt=media&token=2a0a9990-8818-435b-ab89-68e8a699d1fe',
    
  
  },
  {
    id: 12,
    cover:'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-19%20at%204.05.27%20PM%20(1).jpeg?alt=media&token=1be6185e-d822-45c9-bde7-d9f59e875ba4',
    
  
  },
]

export const awards = [
  {
    icon: <i class='fa-solid fa-trophy'></i>,
    num: "32 M	",
    name: "Blue Burmin Award",
  },
  {
    icon: <i class='fa-solid fa-briefcase'></i>,
    num: "43 M",
    name: "Mimo X11 Award",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "51 M",
    name: "Australian UGC Award",
  },
  {
    icon: <i class='fa-solid fa-heart'></i>,
    num: "42 M",
    name: "IITCA Green Award",
  },
]
export const location = [
  {
    id: 1,
    name: "Kangundo",
    Apartments: "Socius Nursery and Primary School",
    Offices: "School",
    cover: "./images/location/city-1.png",
  },
  {
    id: 2,
    name: "Kangundo",
    Apartments: "Socius Nursery and Primary School",
    Offices: "School",
    cover: "./images/location/city-2.png",
  },
  {
    id: 3,
    name: "Kangundo",
    Apartments: "Socius Nursery and Primary School",
    Offices: "School",
    cover: "./images/location/city-3.png",
  },
  {
    id: 4,
    name: "Kangundo",
    Apartments: "Socius Nursery and Primary School",
    Offices: "School",
    cover: "./images/location/city-4.png",
  },
  {
    id: 5,
    name: "Kangundo",
    Apartments: "Socius Nursery and Primary School",
    Offices: "School",
    cover: "./images/location/city-5.png",
  },
  {
    id: 6,
    name: "Kangundo",
    Apartments: "Socius Nursery and Primary School",
    Offices: "School",
    cover: "./images/location/city-6.png",
  },
]
export const team = [
  {
    list: "50",
    cover: "../images/customer/team-1.jpg",
    address: "Liverpool, Canada",
    name: "Sargam S. Singh",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "70",
    cover: "../images/customer/team-2.jpg",
    address: "Montreal, Canada",
    name: "Harijeet M. Siller",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "80",
    cover: "../images/customer/team-3.jpg",
    address: "Denever, USA",
    name: "Anna K. Young",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "51",
    cover: "../images/customer/team-4.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "42",
    cover: "../images/customer/team-5.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "38",
    cover: "../images/customer/team-5.jpg",
    address: "Montreal, USA",
    name: "Adam K. Jollio",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
]
export const price = [
  {
    plan: "Basic",
    price: "29",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    plan: "Standard",
    price: "49",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Platinum",
    price: "79",
    ptext: "2 user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
]
export const footer = [
  {
    title: "LAYOUTS",
    text: [{ list: "Home Page" }, { list: "About Page" }, { list: "Service Page" }, { list: "Property Page" }, { list: "Contact Page" }, { list: "Single Blog" }],
  },
  {
    title: "ALL SECTIONS",
    text: [{ list: "Headers" }, { list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }, { list: "Footers" }],
  },
  {
    title: "COMPANY",
    text: [{ list: "About" }, { list: "Blog" }, { list: "Pricing" }, { list: "Affiliate" }, { list: "Login" }, { list: "Changelog" }],
  },
]
