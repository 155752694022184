import React from "react";
import Back from "../common/Back";
import Heading from "../common/Heading";
import "./about.css";

const About = () => {
  const imageUrl = 'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.15.16%20PM.jpeg?alt=media&token=7e210f70-ff28-4040-b276-6ce0e9ea1093';

  return (
    <>
      <section className='about'>
        <Back name='About Us' title='About Us' cover={imageUrl} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='About Our School' subtitle='' />
            <p>§: About us; We believe in making difference in the local community .
            </p>
          </div>
          <div className='right row'>
            <img src={imageUrl} alt='School' />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
