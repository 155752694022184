import React from "react"
import Back from "../common/Back"
import "./contact.css"

const Contact = () => {
  const imageUrl = 'https://firebasestorage.googleapis.com/v0/b/maryann-15559.appspot.com/o/WhatsApp%20Image%202024-06-18%20at%206.23.58%20PM.jpeg?alt=media&token=a7b5c048-03b7-40da-84d4-dd8eb7f2a24e';

  return (
    <>
      <section className='contact mb'>
        <Back name='Contact Us' title='' cover={imageUrl} />
        <div className='container'>
          <div className='contact-info'>
            <p>Contact us:
            </p>
            <p>1: +254712295739</p>
            <p>2: +46709653646,</p>
            <p>3: +254720263095,</p>


            <p>Email us:</p>
            <p>veronica.socius@gmail.com</p>

          </div>
         
        </div>
      </section>
    </>
  )
}

export default Contact
